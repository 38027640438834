import React from 'react';

export const H1 = (data) => {
	const hTagName = 'h1';
	return wrap(hTagName, data);
};

export const H2 = (data) => {
	const hTagName = 'h2';
	return wrap(hTagName, data);
};

export const H3 = (data) => {
	const hTagName = 'h3';
	return wrap(hTagName, data);
};

const wrap = (HTagName, { children, ...props }) => {
	if (!props.id) {
		throw new Error('id属性が無い。<H?>タグはリンク可能にするためid属性を必須としている。');
	}
	return (
		<div className={`${HTagName}Parent`}>
			<HTagName {...props}>{children}</HTagName>
			<a href={'#' + props.id} className='shareLink' title='ココへのURL'>
				<span role='img' aria-label='ココへのURL'>
					🔗
				</span>
			</a>
			<span style={{ flexGrow: '1' }} />
			<a href='#top' className='shareLink' title='ページの先頭に移動'>
				<span role='img' aria-label='ページの先頭に移動'>
					🔝
				</span>
			</a>
		</div>
	);
};
