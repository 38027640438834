import React from 'react';
import Head from '/src/components/head';
import Layout from '/src/components/layout';

import { H1 } from '/src/components/wrapper/h';

export default () => {
	return (
		<>
			<Head>
				<title>ハロー たらこ</title>
			</Head>
			<Layout>
				<main>
					<H1 id='hello'>Hello</H1>
				</main>
			</Layout>
		</>
	);
};
